import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetMeTypes, GetPricingRuleTypes } from './user.actions';

const initialState = {
  me: null,
  referrals: [],
  cleanData: '',
  credits: {},
  product: null,
  isLoading: false,
  pricingRule: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetMeTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetMeTypes.SUCCESS:
      if (!action.payload.product?.product?.category) {
        delete action.payload.product;
      }

      return {
        ...state,
        me: {
          ...state.me,
          ...action.payload,
        },
        isLoading: false,
      };

    case GetPricingRuleTypes.SUCCESS:
      return {
        ...state,
        pricingRule: action.payload,
        isLoading: false,
      };

    case GetMeTypes.FAILURE:
      return {
        ...state,
        me: null,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
      return initialState;

    case ChangeScaleTypes.SUCCESS:
      return {
        ...initialState,
        me: state.me,
      };

    default:
      return state;
  }
};
