import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetMeTypes = createAction('Users', 'Get me');

export const getMeAction = () => ({
  [RSAA]: {
    endpoint: 'users/me',
    method: 'GET',
    types: [GetMeTypes.REQUEST, GetMeTypes.SUCCESS, GetMeTypes.FAILURE],
  },
});

export const GetPricingRuleTypes = createAction('Pricing rules', 'Get rule');

export const getPricingRuleAction = path => ({
  [RSAA]: {
    endpoint: `pricing_rules?path=${path}`,
    method: 'GET',
    types: [GetPricingRuleTypes.REQUEST, GetPricingRuleTypes.SUCCESS, GetPricingRuleTypes.FAILURE],
  },
});

export const SetEmailPopupTypes = createAction('Users', 'Set Email From Popup');

export const setEmailFromPopupAction = data => ({
  [RSAA]: {
    endpoint: `users/email-popup`,
    method: 'POST',
    body: data,
    types: [SetEmailPopupTypes.REQUEST, SetEmailPopupTypes.SUCCESS, SetEmailPopupTypes.FAILURE],
  },
});

export const GetByRefTypes = createAction('Users', 'Get user by ref');

export const getByRefAction = ref => ({
  [RSAA]: {
    endpoint: `referral/${ref}`,
    method: 'GET',
    types: [GetByRefTypes.REQUEST, GetByRefTypes.SUCCESS, GetByRefTypes.FAILURE],
  },
});

export const MakeBottomlessProductTypes = createAction('Users', 'Make bottomless product');

export const makeBottomlessProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `products/${id}/make-bottomless`,
    method: 'post',
    body: data,
    types: [MakeBottomlessProductTypes.REQUEST, MakeBottomlessProductTypes.SUCCESS, MakeBottomlessProductTypes.FAILURE],
  },
});
