import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { EVENT_CTA_CLICK, trackEvent } from '../../../../../utils/tracker';

export const Button = ({ title: rawTitle = 'See Brands', position }) => {
  const [{ checkout }] = useCookies(['checkout']);
  const answers = useSelector(({ quiz }) => quiz.customRotation);
  const isUnfinishedQuiz = useMemo(() => Object.keys(answers).length > 0, [answers]);

  const title = useMemo(() => (checkout || isUnfinishedQuiz ? 'Finish Checkout' : rawTitle), [
    rawTitle,
    checkout,
    isUnfinishedQuiz,
  ]);

  const onClick = useCallback(() => {
    trackEvent(EVENT_CTA_CLICK, { position });

    if (checkout) {
      window.location.href = `/get_started/${checkout}`;
      return;
    }

    if (isUnfinishedQuiz) {
      window.location.href = '/filter_checkout?finished=true';
      return;
    }

    const howItWorksSection = document.querySelector('#powered-by-bottomless');
    howItWorksSection.scrollIntoView({ behavior: 'smooth' });
  }, [position, checkout, isUnfinishedQuiz]);

  return (
    <button className="btn btn-primary btn-lg cta-services" onClick={onClick}>
      {title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
};
