import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetPetFoodProductsTypes = createAction('Pet food', 'Get products');

export const getPetFoodProductsAction = () => ({
  [RSAA]: {
    endpoint: 'pet-food',
    method: 'GET',
    types: [GetPetFoodProductsTypes.REQUEST, GetPetFoodProductsTypes.SUCCESS, GetPetFoodProductsTypes.FAILURE],
  },
});
