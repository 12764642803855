import { createStore as createBottomlessStore } from '@bottomless/common/src/store/store';

import { authReducer } from './auth';
import { quizReducer } from './quiz';
import { userReducer } from './user';
import { vendorReducer } from './vendor';
import { productReducer } from './products';
import { petFoodReducer } from './pet-food';
import { categoryReducer } from './category';
import { staticData } from '../../store/data';

export const createStore = () =>
  createBottomlessStore(
    {
      auth: authReducer,
      user: userReducer,
      quiz: quizReducer,
      vendor: vendorReducer,
      product: productReducer,
      petFood: petFoodReducer,
      category: categoryReducer,
    },
    undefined,
    { staticData }
  );
