import {
  QUIZ_SAVE_ANSWER,
  CUSTOM_ROTATION_SAVE_ANSWER,
  CUSTOM_ROTATION_SAVE_PERSONALIZED,
  QUIZ_CUSTOM_ROTATION,
} from './quiz.actions';

const initialState = {
  answers: {},
  customRotation: {},
  personalized: null,
  firstProduct: null,
};

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUIZ_SAVE_ANSWER:
      return {
        ...state,
        answers: { ...state.answers, [action.payload.question]: action.payload.answer },
      };

    case CUSTOM_ROTATION_SAVE_ANSWER:
      return {
        ...state,
        customRotation: { ...state.customRotation, [action.payload.question]: action.payload.answer },
        personalized: null,
        firstProduct: null,
      };

    case CUSTOM_ROTATION_SAVE_PERSONALIZED:
      return {
        ...state,
        personalized: action.payload.personalized,
        firstProduct: action.payload.firstProduct,
      };

    case QUIZ_CUSTOM_ROTATION:
      return {
        ...state,
        customRotation: {},
      };

    default:
      return state;
  }
};
