import React from 'react';
import { connect } from 'react-redux';
import { getVendorsPoweredByBottomlessAction } from '../../../store';
import { Vendors } from './components';

const PoweredByBottomless = props => <Vendors {...props} />;

export default connect(
  ({ vendor }) => ({ vendors: vendor.poweredByBottomless || [] }),
  dispatch => ({
    getVendorsPoweredByBottomless: () => dispatch(getVendorsPoweredByBottomlessAction()),
  })
)(PoweredByBottomless);
