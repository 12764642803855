import * as Sentry from '@sentry/browser';
import './polyfills';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'lazysizes';
import * as serviceWorker from './serviceWorker';
import { createStore } from './landingpage/store';
import App from './landingpage/App';
import MainCtaButtonApp from './landingpage/apps/GenericLanding/MainCta/MainCta';
import PoweredByBottomlessApp from './landingpage/apps/GenericLanding/PoweredByBottomless/PoweredByBottomless';
import MyAccountApp from './landingpage/apps/MyAccount/MyAccount';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

if (process.env.REACT_APP_HOTJAR_ID) {
  import(/* webpackChunkName: 'react-hotjar' */ 'react-hotjar')
    .then(({ hotjar }) => {
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
    })
    .catch(error => {
      Sentry.captureException(error);
    });
}

const { store, persistor } = createStore();

ReactDOM.render(
  <Suspense fallback="">
    <App store={store} persistor={persistor}>
      <MainCtaButtonApp position="heading" />
    </App>
  </Suspense>,
  document.querySelector('.main-cta')
);

const moreVendors = document.querySelector('.more-vendors');

ReactDOM.render(
  <Suspense fallback="">
    <App store={store} persistor={persistor}>
      <PoweredByBottomlessApp currentContent={moreVendors.innerHTML} />
    </App>
  </Suspense>,
  moreVendors
);

ReactDOM.render(
  <Suspense fallback="">
    <App store={store} persistor={persistor}>
      <MyAccountApp />
    </App>
  </Suspense>,
  document.querySelector('.my-account')
);

serviceWorker.unregister();
