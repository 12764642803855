/* global hj */
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import qs from 'query-string';
import PropTypes, { instanceOf } from 'prop-types';
import { CookiesProvider, Cookies, withCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { browserMatches } from '@bottomless/common/src/utils/browserMatches';
import { LogoutTypes } from '@bottomless/common/src/store/auth';
import { BrowserRouter as Router } from 'react-router-dom';

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    store: PropTypes.object.isRequired,
    persistor: PropTypes.object.isRequired,
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    const { cookies } = this.props;

    const affiliateCookie = cookies.get('affiliate');

    if (!affiliateCookie) {
      const domain = process.env.REACT_APP_COOKIE_DOMAIN;
      const query = qs.parse(window.location.search);
      const { coupon, rfsn } = query;

      if (coupon || rfsn) {
        const affiliate = {};

        if (coupon) affiliate.coupon = coupon;
        if (rfsn) affiliate.trackingCode = rfsn;

        cookies.set('affiliate', affiliate, { path: '/', domain });
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    const now = Date.now();
    const errorAlreadyExists = window.location.href.match(/error(=(\d+))?/);
    const lastErrorTime = errorAlreadyExists && errorAlreadyExists[2] && Number(errorAlreadyExists[2]);
    const isOldError = !lastErrorTime || (now - lastErrorTime) / 1000 > 60; // a minute ago

    const isChunkLoadError = error.name === 'ChunkLoadError';

    if ((!errorAlreadyExists || isOldError) && isChunkLoadError) {
      this.handleChunkLoadError(now);
    } else {
      this.handleError(errorAlreadyExists, error, errorInfo, now, isOldError);
    }
  }

  handleChunkLoadError(now) {
    const chunkErrorAlreadyExists = window.location.href.match(/chunkError(=(\d+))?/);
    const lastChunkErrorTime =
      chunkErrorAlreadyExists && chunkErrorAlreadyExists[2] && Number(chunkErrorAlreadyExists[2]);
    const isOldChunkError = !lastChunkErrorTime || (now - lastChunkErrorTime) / 1000 > 60; // a minute ago

    if (!chunkErrorAlreadyExists || isOldChunkError) {
      const sufix = window.location.href.match(/\?/) ? `&chunkError=${now}` : `?chunkError=${now}`;
      window.location.replace(
        `${window.location.href.replace(/error(=(\d+))?/, '').replace(/chunkError(=(\d+))?/, '')}${sufix}`
      );
    } else {
      window.location.replace(`${window.location.pathname}?error=${now}`);
    }
  }

  handleError(errorAlreadyExists, error, errorInfo, now, isOldError) {
    if (!errorAlreadyExists || isOldError) {
      this.props.store.dispatch({ type: LogoutTypes.SUCCESS });

      const sufix = window.location.href.match(/\?/) ? `&error=${now}` : `?error=${now}`;
      window.location.replace(`${window.location.href.replace(/error(=(\d+))?/, '')}${sufix}`);
    } else if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    } else if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        if ('hj' in window && hj.globals) scope.setExtra('hotjar_user_id', hj.globals.get('userId'));

        Sentry.captureException(error);
      });

      if ('hj' in window) {
        window.hj('tagRecording', ['Error']);
      }
    }
  }

  render() {
    const { children, store, persistor } = this.props;

    return (
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>{children}</Router>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}

export default withCookies(App);
