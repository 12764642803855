import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDataEffect } from '@bottomless/common/hooks';
import { chunk } from 'lodash-es';

const hardcodedInMainSection = [
  '5f10f290a359b8004585dc5c', // Onyx
  '5ed66f4b0dd18000349fabad', // Klatch
  '5a32e6796bd23b00147e33bd', // Olympia
  '633bcbe52fd8b90045832f41', // Black&White
  '588fe35907cb391100058c15', // Caffe Vita
  '642c514c27c379004725f743', // Bearded Bros
  '648837735ebe70004386dad7', // Hypersonic
];

export const Vendors = ({ getVendorsPoweredByBottomless, vendors: rawVendors, currentContent }) => {
  useDataEffect(getVendorsPoweredByBottomless);

  const vendorChunks = useMemo(
    () =>
      chunk(
        rawVendors.filter(vendor => !hardcodedInMainSection.includes(vendor._id)),
        6
      ),
    [rawVendors]
  );

  const innerHTML = useMemo(() => {
    var div = document.createElement('div');
    div.innerHTML = currentContent;

    return div.querySelector('.carousel-item').innerHTML;
  }, [currentContent]);

  return (
    <>
      <div className="carousel-item active" dangerouslySetInnerHTML={{ __html: innerHTML }}></div>
      {vendorChunks.map((vendorChunk, i) => (
        <div className="carousel-item" key={i}>
          <div className="row align-items-center justify-content-center">
            {vendorChunk.map(vendor => (
              <div key={vendor._id} className="col-6 col-md-3 col-lg-2 text-center mb-4 mb-lg-0">
                <a target="_blank" href={vendor.website} className="d-block" rel="noreferrer">
                  <img className="img-fluid" src={vendor.img_url} alt={vendor.name} />
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

Vendors.propTypes = {
  getVendorsPoweredByBottomless: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  currentContent: PropTypes.string,
};
