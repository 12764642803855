import { GetProductsTypes, GetProductAttributesTypes } from './products.actions';

const initialState = {
  data: [],
  isLoading: false,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetProductsTypes.REQUEST:
      return {
        ...state,
        data: [],
        isLoading: true,
      };

    case GetProductsTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data
          ? action.payload.data
              .map(({ variants, ...product }) => ({
                ...product,
                variants: variants.filter(variant => variant.available !== false),
              }))
              .filter(({ variants }) => variants.length)
          : [],
        isLoading: false,
      };

    case GetProductsTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case GetProductAttributesTypes.SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };

    default:
      return state;
  }
};
