import { GetVendorsPoweredByBottomlessTypes, GetVendorsTypes, GetVendorTypes } from './vendor.actions';

const initialState = {
  data: [],
  poweredByBottomless: [],
  isLoading: false,
  selectedVendor: null,
};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetVendorsTypes.REQUEST:
    case GetVendorTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetVendorsTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case GetVendorsTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GetVendorTypes.SUCCESS:
      return {
        ...state,
        selectedVendor: action.payload,
        isLoading: false,
      };
    case GetVendorsPoweredByBottomlessTypes.SUCCESS:
      return {
        ...state,
        poweredByBottomless: action.payload.data,
      };
    case GetVendorTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
        selectedVendor: null,
      };

    default:
      return state;
  }
};
