import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const QUIZ_SAVE_ANSWER = 'Quiz/Save answer';

export const saveQuizAnswerAction = (question, answer) => ({
  type: QUIZ_SAVE_ANSWER,
  payload: { question, answer },
});

export const CUSTOM_ROTATION_SAVE_ANSWER = 'Quiz/Custom rotation';

export const saveCustomRotationAnswerAction = (question, answer) => ({
  type: CUSTOM_ROTATION_SAVE_ANSWER,
  payload: { question, answer },
});

export const CUSTOM_ROTATION_SAVE_PERSONALIZED = 'Quiz/Personalized';

export const saveCustomRotationPersonalizedAction = (personalized, firstProduct) => ({
  type: CUSTOM_ROTATION_SAVE_PERSONALIZED,
  payload: { personalized, firstProduct },
});

export const QUIZ_CUSTOM_ROTATION = 'Quiz/Clear custom rotation';

export const clearCustomRotationAction = () => ({
  type: QUIZ_CUSTOM_ROTATION,
});

export const SendQuizAnswers = createAction('Quiz', 'Send answers');

export const sendQuizAnswersAction = data => ({
  [RSAA]: {
    endpoint: 'quiz/send-answers',
    method: 'POST',
    body: data,
    types: [SendQuizAnswers.REQUEST, SendQuizAnswers.SUCCESS, SendQuizAnswers.FAILURE],
  },
});

export const SendPersonalizedAnswers = createAction('Quiz', 'Send personalized');

export const sendPersonalizedAnswersAction = data => ({
  [RSAA]: {
    endpoint: 'quiz/send-personalized',
    method: 'POST',
    body: data,
    types: [SendPersonalizedAnswers.REQUEST, SendPersonalizedAnswers.SUCCESS, SendPersonalizedAnswers.FAILURE],
    fetch: async (...args) => {
      const res = await fetch(...args);
      const { likes, product, feedback, ...json } = await res.json();
      return new Response(JSON.stringify({ ...json, product: { ...product, feedback, likes } }), {
        // Custom success/error status based on an `error` key in the API response
        status: json.error ? 500 : 200,
        headers: { 'Content-Type': 'application/json' },
      });
    },
  },
});

export const GetAlternativeProductsType = createAction('Quiz', 'Get alternative products');

export const getAlternativeProductsAction = data => ({
  [RSAA]: {
    endpoint: 'quiz/alternative-products',
    method: 'POST',
    body: data,
    types: [GetAlternativeProductsType.REQUEST, GetAlternativeProductsType.SUCCESS, GetAlternativeProductsType.FAILURE],
  },
});
