import { GetPetFoodProductsTypes } from './pet-food.actions';

const initialState = {
  products: [],
  isLoading: false,
};

export const petFoodReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetPetFoodProductsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetPetFoodProductsTypes.SUCCESS:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };

    case GetPetFoodProductsTypes.FAILURE:
      return {
        ...state,
        products: [],
        isLoading: false,
      };

    default:
      return state;
  }
};
