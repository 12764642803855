import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const SaveReferralTypes = createAction('Auth', 'Change scale');

export const saveReferralAction = data => ({
  [RSAA]: {
    endpoint: 'auth/save-referral',
    method: 'POST',
    body: data,
    types: [SaveReferralTypes.REQUEST, SaveReferralTypes.SUCCESS, SaveReferralTypes.FAILURE],
  },
});

export const SaveReferralPilotTypes = createAction('Auth', 'Save Referral Pilot');

export const saveReferralPilotAction = data => ({
  [RSAA]: {
    endpoint: 'auth/save-referral-pilot',
    method: 'POST',
    body: data,
    types: [SaveReferralPilotTypes.REQUEST, SaveReferralPilotTypes.SUCCESS, SaveReferralPilotTypes.FAILURE],
  },
});

export const SaveLandingPageTypes = createAction('Auth', 'Landing page');

export const saveLandingPageAction = data => ({
  [RSAA]: {
    endpoint: 'auth/landing-page',
    method: 'POST',
    body: { ...data },
    types: [SaveLandingPageTypes.REQUEST, SaveLandingPageTypes.SUCCESS, SaveLandingPageTypes.FAILURE],
  },
});

export const SaveLastLandingRuleTypes = createAction('Auth', 'Last landing rule');

export const saveLastLandingRuleAction = variant => ({
  [RSAA]: {
    endpoint: 'auth/last-landing-rule',
    method: 'POST',
    types: [SaveLastLandingRuleTypes.REQUEST, SaveLastLandingRuleTypes.SUCCESS, SaveLastLandingRuleTypes.FAILURE],
    ...(variant !== undefined ? { body: { variant } } : {}),
  },
});

export const GetStartedTypes = createAction('Auth', 'Get started');

export const getStartedAction = data => ({
  [RSAA]: {
    endpoint: 'auth/get-started',
    method: 'POST',
    body: {
      slug: window.location.pathname,
      query: window.location.search,
      ...data,
    },
    types: [GetStartedTypes.REQUEST, GetStartedTypes.SUCCESS, GetStartedTypes.FAILURE],
  },
});
