import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const MyAccountComponent = ({ me }) => {
  if (me) {
    return (
      <a href="/profile" className="btn btn-sm btn-outline-primary">
        My Account
      </a>
    );
  }
  return (
    <a href="/login" className="btn btn-sm btn-outline-primary">
      Login
    </a>
  );
};

MyAccountComponent.propTypes = {
  me: PropTypes.object,
};

const MyAccountApp = withRouter(connect(({ user }) => ({ me: user.me }))(MyAccountComponent));

export default MyAccountApp;
