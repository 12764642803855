import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductsTypes = createAction('Products', 'Get all');

export const getProductsAction = ({ vendorId: vendor, type, category = '', limit } = { vendorId: '' }) => {
  const params = { vendor, type, ...(category && { category }), ...(limit && { limit }) };

  return {
    [RSAA]: {
      endpoint: `products${params ? `?${qs.stringify(params)}` : ''}`,
      method: 'GET',
      types: [GetProductsTypes.REQUEST, GetProductsTypes.SUCCESS, GetProductsTypes.FAILURE],
    },
  };
};

export const GetProductAttributesTypes = createAction('Products', 'Get attributes');

export const getProductAttributesAction = () => ({
  [RSAA]: {
    endpoint: 'products/attributes',
    method: 'GET',
    types: [GetProductAttributesTypes.REQUEST, GetProductAttributesTypes.SUCCESS, GetProductAttributesTypes.FAILURE],
  },
});

export const GetGroceryProductsTypes = createAction('Grocery Products', 'Subscribe');

export const subscribeToGroceryProductsAction = data => {
  return {
    [RSAA]: {
      endpoint: 'grocery-products/subscribe',
      method: 'POST',
      body: data,
      types: [GetGroceryProductsTypes.REQUEST, GetGroceryProductsTypes.SUCCESS, GetGroceryProductsTypes.FAILURE],
    },
  };
};
