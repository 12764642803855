import { RSAA } from 'redux-api-middleware';
import qs from 'query-string';
import { createAction } from '@bottomless/common/store';

export const GetVendorsTypes = createAction('Vendor', 'Get Vendors');

export const getVendorsAction = params => ({
  [RSAA]: {
    endpoint: `vendors${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetVendorsTypes.REQUEST, GetVendorsTypes.SUCCESS, GetVendorsTypes.FAILURE],
  },
});

export const GetVendorTypes = createAction('Vendor', 'Get Vendor');

export const getVendorAction = slug => ({
  [RSAA]: {
    endpoint: `vendors/${slug}`,
    method: 'GET',
    types: [GetVendorTypes.REQUEST, GetVendorTypes.SUCCESS, GetVendorTypes.FAILURE],
  },
});

export const GetVendorsPoweredByBottomlessTypes = createAction('Vendor', 'Get Vendors Powered by Bottomless');

export const getVendorsPoweredByBottomlessAction = () => ({
  [RSAA]: {
    endpoint: 'vendors/powered-by-bottomless',
    method: 'GET',
    types: [
      GetVendorsPoweredByBottomlessTypes.REQUEST,
      GetVendorsPoweredByBottomlessTypes.SUCCESS,
      GetVendorsPoweredByBottomlessTypes.FAILURE,
    ],
  },
});
